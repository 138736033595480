import React from "react"
import { graphql } from "gatsby"
import { LinkButton } from "gatsby-interface"
import PropTypes from "prop-types"
import Layout from "../../layout"
import SiteHead from "../../head"
import Pagination from "../../components/blog/pagination"
import NewsletterSignup from "../../components/blog/newsletter-signup"
import BlogPostPreviewItem from "../../components/blog/blog-post-preview-item"
import { SkipNavTarget } from "../../components/shared/components/SkipNav"
import { contentPositionerCss } from "../../components/shared/styles"

const eyebrowHeadlineCss = theme => ({
  marginBottom: theme.space[9],
  textTransform: "uppercase",
  letterSpacing: theme.letterSpacings.tracked,
  fontSize: theme.fontSizes[1],
  fontWeight: theme.fontWeights.body,
  color: theme.colors.grey[70],
  borderTop: `1px solid ${theme.colors.grey[30]}`,
  paddingTop: theme.space[5],
})

export function Head(props) {
  return (
    <SiteHead
      {...props}
      title="GatsbyJS Blog"
      description="Case studies, stories, and releases from the Gatsby team and community"
    />
  )
}

export default function BlogListPage(props) {
  const { allWpPost, featuredPosts } = props.data
  const { numPages, currentPage, tags, isHome } = props.pageContext

  const firstPost = allWpPost.nodes[0]

  return (
    <Layout pathname={props.location.pathname}>
      <main>
        <SkipNavTarget />
        <div
          css={theme => [
            contentPositionerCss({ theme }),
            {
              paddingTop: theme.space[7],
              paddingBottom: theme.space[10],
              display: "flex",
              flexWrap: "wrap",
              gap: theme.space[3],
              [theme.mediaQueries.desktop]: {
                paddingBottom: theme.space[12],
              },
            },
          ]}
        >
          {tags &&
            tags
              .filter(({ count, name }) => name && count)
              .map(({ slug, count, name }) => (
                <LinkButton
                  to={`/blog/tags/${slug}`}
                  css={{
                    textTransform: "capitalize",
                    flexShrink: 0,
                  }}
                  key={slug}
                  variant="SECONDARY"
                  size="S"
                >{`${name} (${count})`}</LinkButton>
              ))}
        </div>
        {allWpPost && (
          <div
            css={theme => [
              contentPositionerCss({ theme }),
              {
                display: "grid",
                gridAutoColumns: "2fr 1fr",
                gap: theme.space[10],
              },
            ]}
          >
            {isHome && <NewsletterSignup />}
            <div css={{ gridColumn: "1/2", gridRow: "1/-1" }}>
              {firstPost && (
                <BlogPostPreviewItem
                  post={firstPost}
                  key={firstPost.slug}
                  hasHero={isHome}
                  isHero={isHome}
                />
              )}
            </div>
            {isHome && featuredPosts && (
              <div
                css={theme => ({
                  display: "none",
                  [theme.mediaQueries.desktop]: {
                    display: "initial",
                    gridColumn: "2/3",
                  },
                })}
              >
                <h3 css={eyebrowHeadlineCss}>Popular Posts</h3>
                {featuredPosts.nodes.map(post => (
                  <BlogPostPreviewItem post={post} key={post.slug} isFeatured />
                ))}
              </div>
            )}
            <div
              css={theme => ({
                gridColumn: "1/2",
                gridRow: "2",
                gap: theme.space[10],
                display: "grid",
                gridTemplateColumns:
                  "repeat(auto-fill, minmax(min(28rem, 100%), 1fr))",
              })}
            >
              {isHome && firstPost && (
                <h2 css={eyebrowHeadlineCss}>New Posts</h2>
              )}
              {allWpPost.nodes.slice(1).map(post => {
                return (
                  <BlogPostPreviewItem post={post} key={post.slug} hasHero />
                )
              })}
            </div>
            <Pagination numPages={numPages} currentPage={currentPage} />
          </div>
        )}
      </main>
    </Layout>
  )
}

BlogListPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  dimensions: PropTypes.object,
  windowHeight: PropTypes.number,
}

export const pageQuery = graphql`
  query BlogListPage($skip: Int!, $limit: Int!) {
    allWpPost(sort: { fields: date, order: DESC }, limit: $limit, skip: $skip) {
      nodes {
        ...BlogPostPreviewInformation
      }
    }
    featuredPosts: allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { in: "Featured" } } } }
      }
      sort: { fields: date, order: DESC }
      limit: 3
      skip: $skip
    ) {
      nodes {
        ...BlogPostPreviewInformation
      }
    }
  }
`
